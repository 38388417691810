$(document).ready(function() {
  $(".stepper").click(function(e) {
    var stepper$ = $(this);
    var target$ = stepper$.parent().find("." + stepper$.data("target-class"));

    var current = parseFloat(target$.val());

    var newValue;

    // if a limit is specified, only add to the current value if that limit
    // is not exceeded.
    // compare with strings to allow for limits of 0.
    var upperLimit = stepper$.data("limit-plus") != undefined ? stepper$.data("limit-plus").toString() : "";
    var lowerLimit = stepper$.data("limit-minus") != undefined ? stepper$.data("limit-minus").toString() : "";

    if (upperLimit.length === 0 && lowerLimit.length === 0) {
      newValue = current + parseFloat(stepper$.data("delta"));
    } else {
      // switch back to floats for comparisons
      upperLimit = parseFloat(upperLimit);
      lowerLimit = parseFloat(lowerLimit);

      if (current > upperLimit) {
        newValue = upperLimit;
      } else if (current === upperLimit && parseFloat(stepper$.data("delta")) > 0) {
        newValue = upperLimit;
      } else if (current < lowerLimit) {
        newValue = lowerLimit;
      } else if (current === lowerLimit && parseFloat(stepper$.data("delta")) < 0) {
        newValue = lowerLimit;
      } else {
        newValue = current + parseFloat(stepper$.data("delta"));
      }
    }

    if (isNaN(newValue)) {
      var firstValue = 1;
      if (target$.data("first-value") != undefined) {
        firstValue = target$.data("first-value");
      }
      newValue = target$.val() === "" ? firstValue : 0;
    }
    target$.val(newValue);
    checkLimits();
  });
  
  if ($(".pledge-stepper").length > 0) {
    var steps = $(".pledge-stepper").parent().data("schedge") === "new" ?
      ["$0", "$1", "$5", "$10", "$20", "$40", "$80", "$160", "$320", "$640", "$1280", "$2560"]
      : ["$0", "$5", "$10", "$30", "$90", "$270", "$810", "$2430", "$7290"];
    $(".pledge-stepper").parent().data("steps", steps);
  }
  $(".pledge-stepper").click(function(e) {
    var pledgeSteps = $(this).parent().data("steps"); // has dollar signs
    var stepper$ = $(this);
    var target$ = stepper$.parent().find("." + stepper$.data("target-class"));
    var currentIndex = pledgeSteps.indexOf(target$.val());
    if (currentIndex <= 0 && stepper$.hasClass("minus")) { 
      stepper$.addClass("disabled");
      return; 
    }
    if (currentIndex === (pledgeSteps.length - 1) && stepper$.hasClass("plus")) { 
      stepper$.addClass("disabled");
      return; 
    }
    var newIndex = currentIndex + 1;
    if (stepper$.hasClass("minus")) {
      if (stepper$.data("limit-minus") === undefined ||
          pledgeSteps.indexOf(stepper$.data("limit-minus")) < currentIndex) {
        newIndex = currentIndex - 1;
      } else {
        newIndex = currentIndex;
      }
    }
    if (pledgeSteps.indexOf(stepper$.data("limit-minus")) === newIndex) {
      stepper$.parent().find(".minus").addClass("disabled");
    } else {
      stepper$.parent().find(".minus").removeClass("disabled");
    }
    if (pledgeSteps.indexOf(stepper$.data("limit-plus")) === newIndex) {
      stepper$.parent().find(".plus").addClass("disabled");
    } else {
      stepper$.parent().find(".plus").removeClass("disabled");
    }
    target$.val(pledgeSteps[newIndex]);
    target$.change();
  });

  $("form.pledgecap").submit(function() {
    var pledgeCap = $(this).find(".stepper-control input[type='text']").val();
    pledgeCap = pledgeCap.replace('$', '');
    $(this).find(".stepper-control input[type='text']").val(pledgeCap);
    return true;
  });

  function checkLimits() {
    $(".stepper-control .plus, .stepper-control .minus").each(function() {
      var stepper$ = $(this);
      var target$ = stepper$.parent().find("." + stepper$.data("target-class"));
      var sign = $(this).hasClass("plus") ? "plus" : "minus"
      var current = target$.val() 

      // limit could be undefined, or it might be empty or blank I suppose, 
      // so first let us ensure it has *some* value
      var limit = stepper$.data("limit-"+sign) != undefined ? 
        stepper$.data("limit-"+sign).toString() : "";

      // and then if that value is empty, we can enable & return
      if (limit.length === 0) {
        $(this).removeClass("disabled");
        return;
      }

      if (stepper$.hasClass("pledge-stepper")) {
        // pledge steppers have these inconvenient $ signs to deal with
        current = parseFloat(current.replace('$',""))
        limit = parseFloat(limit.replace('$',""))
      } else {
        current = parseFloat(current)
        limit = parseFloat(limit)
      }

      if (sign === "plus") {
        $(this).toggleClass("disabled", current >= limit)
      } else if (sign === "minus") {
        $(this).toggleClass("disabled", current <= limit)
      }
    })
  }

  checkLimits();
});
