$(document).ready(function() {
    // this javascript should run on all our pages
    // It's used so we know when the transitions are complete
    $('.modal').on('hidden.bs.modal', function (e) {
        $(this).removeClass("modal-shown");
        $(this).addClass("modal-hidden");
    })

    $('.modal').on('shown.bs.modal', function (e) {
        $(this).removeClass("modal-hidden");
        $(this).addClass("modal-shown");
    })
});